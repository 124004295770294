import styled, { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { Link } from 'gatsby'
import { theme } from './ThemeStyles'

export const GlobalStyles = createGlobalStyle`
    ${normalize}

    html {
        box-sizing: border-box;
    }

    body {
        color: ${theme.grey};
        font-size: 18px;
        overflow-x: hidden;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }

    &::selection {
        background: ${theme.black};
        color: ${theme.white}
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 8px;
        }
    }

    ol {
        padding-left: 25px;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    img {
        height: auto;
        width: 100%;
    }

    .centered {
        text-align: center;
    }

    .title-margin {
        margin-bottom: 24px;
    }

    blockquote {
        margin: 20px 0 20px 32px;
        font-family: georgia;
    }

    p {
        margin: 0 0 1em;
    }

    .bg {
        &-tan {
            background: ${theme.tan};
        }
    }

    .margin-center {
        margin: 0 auto;
    }

    .cols {
        display: flex;
        flex-direction: column;

        @media(min-width: 900px) {
            flex-direction: row;
            justify-content: space-between;
        }

        &--50 {
            padding-left: 32px;
            padding-right: 32px;
            @media(min-width: 900px) {
                padding-left: 64px;
                padding-right: 64px;
                width: 90%;
            }
            .col {
                @media(min-width: 900px) {
                    width: 48%;
                }
                p {
                    line-height: 1.5;
                }
            }
        }

        &--twothirds {
            .col {
                @media(min-width: 900px) {
                    &:first-child {
                        width: 40%;
                    }
                    &:last-child {
                        width: 60%;
                    }
                }
            }
        }
    }

    .top-padding {
        padding-top: 52px;
        @media(min-width: 700px) {
            padding-top: 96px;
        }
        &-less {
            padding-top: 32px;
            @media(min-width: 700px) {
                padding-top: 48px;
            }
        }
    }

    .bottom-padding {
        padding-bottom: 52px;
        @media(min-width: 700px) {
            padding-bottom: 96px;
        }
        &-less {
            padding-bottom: 32px;
            @media(min-width: 700px) {
                padding-bottom: 48px;
            }
        }
    }

    .sub-heading {
        font-size: 1.35em;
        @media(min-width: 700px) {
            font-size: 1.875em;
        }
    }

    form {
        input,
        textarea {
            -webkit-appearance: none;
            border: 1px solid ${theme.green};
            border-radius: 0;
            width: 100%;
            @media(min-width: 700px) {
                width: 100%;
            }
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="message"],
        textarea {
            padding: 10px;
            color: ${theme.black};
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        label span {
            position: absolute;
            left: -10000px;
            top: auto;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }

        textarea {
            min-height: 220px;
            resize: none;
        }

        input::placeholder,
        textarea::placeholder {
            color: ${theme.black};
            font-size: 17px;
            font-weight: 400;
        }
    }
`;

export const Logo = styled(Link)`
    display: inline-block;
    max-width: 170px;

    @media(min-width: 700px) {
        max-width: 220px;
    }
`;

export const Eyebrow = styled.p`
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

