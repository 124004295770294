import React from 'react'
import {
    GlobalStyles,
    Typography
} from '../styles'
import Header from './Header'
import Footer from './Footer'

const Layout = ({children}) => {
    return (
        <>
           <GlobalStyles />
           <Typography />
           <Header />
            <main>
                {children}
            </main>
           <Footer />
        </>
    )
}

export default Layout
