import { createGlobalStyle } from 'styled-components'

export const Typography = createGlobalStyle`
    h1, h2, p {
        font-size: clamp(var(--min), var(--val), var(--max));
    }

    h1 {
        --min: 2.5em;
        --val: 5vw;
        --max: 4em;
    }

    h2 {
        --min: 1.5em;
        --val: 4vw;
        --max: 2.778em;
    }
    
    p {
        --min: 1em;
        --val: 2.5vw;
        --max: 1.25em;
    }

    body {
        font-family: futura-pt, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h1, h2, h3, h4, h5,h6 {
        font-family: ivypresto-display, serif;
        font-weight: 500;
    }

    p {
        font-weight: 400;
    }
    
`;