import styled from 'styled-components'
import { PrimaryButton, theme } from '../../styles'
import { motion } from 'framer-motion'

export const HeaderElm = styled.header`
    position: absolute;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    justify-content: space-between;
    width: 100%;
    background: ${({activeNav}) => (activeNav ? `${theme.tan}` : ``)};
    transition: ease 0.2s;
    z-index: 5;

    @media(min-width: 870px) {
        padding: 12px;
        padding: 12px 72px;
    }
`;

export const HeaderLogo = styled.div`
    filter: ${({activeNav}) => (activeNav ? `brightness(0)` : ``)};
    transition: linear 0.25s;
`;

export const HeaderNavigation = styled(motion.nav)`
    position: absolute;
    left: 0;
    top: 99%;
    height: calc(100vh - 118px);
    width: 100vw;
    background: ${theme.tan};
    display: ${({activeNav}) => (activeNav ? "block" : "none")};
    z-index: 5;
    transition: ease 0.2s;

    @media(min-width: 870px) {
        position: static;
        display: block;
        top: auto;
        height: auto;
        width: auto;
        background: none;
        opacity: 1 !important;
        transform: none !important;
    }
`;

export const HeaderLinks = styled(motion.ul)`
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    text-align: center;

    @media(min-width: 870px) {
        flex-direction: row;
        padding-top: 0;
        opacity: 1 !important;
    }

    li {
        margin: 0 0 24px 12px;
        @media(min-width: 870px) {
            display: flex;
            align-items: center;
            margin: 0 0 0 32px;
            opacity: 1 !important;
        }

        a {
            position: relative;
            display: inline-block;
            padding: 6px 0;
            color: ${theme.grey};
            font-family: ivypresto-display, serif;
            font-size: 2em;
            text-transform: uppercase;

            @media(min-width: 870px) {
                color: ${theme.white};
                font-family: futura-pt, sans-serif;
                font-size: 1em;
                font-weight: 500;
                text-shadow: 1px 1px 1px ${theme.black};
            }
        }
    }

    ${PrimaryButton} {
        padding: 16px 32px;
        color: ${theme.white};
        font-family: futura-pt, sans-serif;
        font-size: 1em;
    }
`;

export const HeaderBurger = styled.button`
    position: relative;
    height: 40px;
    padding: 0;
    width: 40px;
    background: none;
    border: none;
    cursor: pointer;

    @media(min-width: 870px) {
        display: none;
    }

    span {
        position: absolute;
        left: 0;
        height: 2px;
        width: 100%;
        background: ${({ activeNav }) => (activeNav ? `${theme.darkBrown}` : `${theme.white}`) };
        transition: ease 0.2s;

        &:first-child {
            top: ${({ activeNav }) => (activeNav ? "18px" : "12px") }; 
            transform: ${({activeNav}) => (activeNav ? "rotate(45deg)" : "rotate(0)")};
        }
        &:last-child {
            top: ${({ activeNav }) => (activeNav ? "18px" : "26px") }; 
            transform: ${({activeNav}) => (activeNav ? "rotate(-45deg)" : "rotate(0)")};
        }
    }
`;

export const HeaderSocial = styled(motion.ul)`
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    color: ${theme.green};

    @media(min-width: 870px) {
        display: none;
    }

    li {
        margin-left: 12px;
    }

    a {
        font-size: 2.25em;
    }
`;