import styled from 'styled-components'
import { theme } from '../../styles'

export const FooterElm = styled.footer`
    padding: 48px 12px 20px;
    background: ${theme.grey};
    color: ${theme.white};

    @media(min-width: 700px) {
        flex-direction: row;
        padding: 48px 40px 20px;
    }
`;

export const FooterAddress = styled.p`
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
    
    @media(min-width: 700px) {
        margin: 0 0 12px;
        line-height: 1;
    }

    @media(min-width: 1100px) {
        margin: 0;
    }
    .phone {
        padding: 8px 0 0;
        text-align: left;
        @media (min-width: 700px) {
            text-align: center;
        }
    }
`;

export const FooterLegal = styled.p`
    margin: 0 0 20px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.5;
    text-transform: uppercase;
    @media (min-width: 700px) {
        margin: 0 0 12px;
    }
    @media(min-width: 1100px) {
        margin: 0;
        line-height: 1;
    }
`;

export const FooterNav = styled.nav`
    display: flex;
    flex-direction: column;
    
    @media(min-width: 700px) {
        align-items: center;
    }

    @media (min-width: 1100px) {
        flex-direction: row;
        justify-content: space-between;
    }

    ul {
        display: flex;
        flex-direction: column;
        margin: 0;

        @media(min-width: 700px) {
            flex-direction: row;
        }

        li {
            margin-bottom: 12px;
            font-size: 1.15em;
            @media(min-width: 700px) {
                margin-left: 1.5em;
                margin-bottom: 0;
                line-height: 1;
            }
        }
    }

    a {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
`;

export const FooterIG = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h2 {
        margin-bottom: 0;
    }
`;

export const FooterIGWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 32px;
    padding: 12px 0 0;
    width: 100%;

    @media(min-width: 500px) {
        grid-template-columns: 1fr 1fr 1fr;
        
    }

    @media(min-width: 700px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @media (min-width: 1000px) {
        margin-bottom: 64px;
        padding: 32px 0 0;
    }

    .gatsby-image-wrapper {
        height: 100%;
    }
`;