import React, { useState } from 'react'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import {
    HeaderBurger,
    HeaderElm,
    HeaderLogo,
    HeaderLinks,
    HeaderNavigation
} from './HeaderStyles'
import {
    Logo,
    PrimaryButton
} from '../../styles'
import LogoImg from '../../images/ancient-olives-logo.svg'

const Header = () => {
    const [activeNav, setActiveNav] = useState(false);

    const headerLinks = [
        {
            linkName: "Home",
            linkUrl: "/"
        },
        {
            linkName: "Our Trees",
            linkUrl: "/our-trees/"
        },
        {
            linkName: "Contact",
            linkUrl: "/contact/"
        }
    ]

    const transition = {
        duration: 0.25,
        ease: [0.43, 0.13, 0.23, 0.96]
    }

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                delay: 0.1,
                ...transition
            }
        }
    }
    
    const item = {
        hidden: {
            opacity: 0.1,
        },
        show: {
            opacity: 1,
            transition: {
                y: {
                    stiffness: 1000,
                    velocity: -100,
                    ease: "linear",
                    ...transition
                }
              }
        }
    }

    const headerPanel = {
        hidden: { opacity:"0" },
        show: {
            opacity:"1",
            transition: {
                duration: 0.2,
                ease: "circInOut",
            }
        }       
    }

    return (
        <HeaderElm activeNav={activeNav}>
            <HeaderLogo activeNav={activeNav}>
                <Logo to="/">
                    <img src={LogoImg} alt="Ancient Olives Logo" />
                </Logo>
            </HeaderLogo>
            <HeaderNavigation
                activeNav={activeNav}
                variants={headerPanel}
                animate={activeNav ? "show" : "hidden"}
            >
                <HeaderLinks
                    activeNav={activeNav}
                    variants={container}
                    animate={activeNav ? "show" : "hidden"}
                >
                    {headerLinks.map(link => (
                        <motion.li variants={item} key={link.linkName}>
                            <Link to={link.linkUrl}>{link.linkName}</Link>
                        </motion.li>
                    ))}
                    <motion.li>
                        <PrimaryButton href={`tel:7603213537`} modifiers={['grey']}>
                            760.321.3537
                        </PrimaryButton>
                    </motion.li>
                </HeaderLinks>
            </HeaderNavigation>
            <HeaderBurger activeNav={activeNav} onClick={() => setActiveNav(!activeNav)}>
                <span></span>
                <span></span>
            </HeaderBurger>
        </HeaderElm>
    )
}

export default Header
