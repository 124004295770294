import React from 'react'

const SectionHeading = ({children}) => {
    return (
        <h2 className="centered" style={{marginBottom: "24px", lineHeight: "1.5"}}>
            {children}
        </h2>
    )
}

export default SectionHeading
