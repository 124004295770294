import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
    FooterElm,
    FooterAddress,
    FooterNav,
    FooterIG,
    FooterIGWrapper,
    FooterLegal
} from './FooterStyles'
import SectionHeading from '../SectionHeading'

const Footer = () => {
    const footerLinks = [
        {
            linkName: "Home",
            linkUrl: "/"
        },
        {
            linkName: "Our Trees",
            linkUrl: "/our-trees/"
        },
        {
            linkName: "Contact",
            linkUrl: "/contact/"
        }
    ]

    const data = useStaticQuery(graphql`
        {
			allInstagramContent(limit: 6) {
				nodes {
					caption
					media_url
					localFile {
						childImageSharp {
							gatsbyImageData(
								layout: FULL_WIDTH, 
								placeholder: BLURRED
							)
						}
					}
				}
			}
        }
    `);

    const IgImages = data.allInstagramContent.nodes;

    return (
        <FooterElm>
            <FooterIG>
                <SectionHeading className="centered">
                    <a href="https://www.instagram.com/ancient.olives/" target="_blank" rel="noreferrer noopener">
                        @ancient.olives
                    </a>
                </SectionHeading>
                <FooterIGWrapper>
                    {IgImages.map(image => (
                        <GatsbyImage
                            image={image.localFile.childImageSharp.gatsbyImageData}
                            alt={image.caption}
                        />
                    ))}
                </FooterIGWrapper>
            </FooterIG>
            
            <FooterNav>
                <FooterLegal>
                    {(new Date().getFullYear())} Ancient olives. All rights reserved.
                </FooterLegal>
                <FooterAddress>
                    457 n palm canyon drive, palm springs, california 92262
                    <p className="phone">
                        <a href="tel:7603213537">760.321.3537</a>
                    </p>
                </FooterAddress>
                <ul>
                    {footerLinks.map(link => (
                        <li key={link.linkName}>
                            <Link to={link.linkUrl}>
                                {link.linkName}
                            </Link>
                        </li>
                    ))}
                </ul>
            </FooterNav>
        </FooterElm>
    )
}

export default Footer
