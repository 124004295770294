import styled from 'styled-components'
import { Link } from 'gatsby'
import { theme } from './index'
import { applyStyleModifiers } from 'styled-components-modifiers'

const BUTTON_MODIFIERS = {
    tan: () => `
        background: ${theme.tan};
        border: 1px solid ${theme.grey};
        color: ${theme.grey};
    `,
    grey: () => `
        background: ${theme.grey};
        border: 1px solid ${theme.grey};
        color: ${theme.white};
    `
}

export const PrimaryButton = styled(Link)`
    display: inline-block;
    min-width: 215px;
    padding: 20px 24px;
    background: ${theme.white};
    border: 1px solid ${theme.greyBorder};
    color: ${theme.black};
    font-size: 0.938em;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    transition: linear 0.25s;
    ${applyStyleModifiers(BUTTON_MODIFIERS)};

    @media(min-width: 900px) {
        padding: 20px 56px;
    }
`;

export const FormButton = styled.button`
    display: inline-block;
    padding: 20px 24px;
    background: ${theme.white};
    border: 1px solid ${theme.greyBorder};
    color: ${theme.black};
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    transition: linear 0.25s;
    ${applyStyleModifiers(BUTTON_MODIFIERS)};

    @media(min-width: 900px) {
        padding: 20px 56px;
        font-size: 16px;
    }
`;
